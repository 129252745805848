import Rails from '@rails/ujs'
import 'init'

// FUNCTIONS
import { manageCookieConsent } from 'shared/cookie_consent'
import { manageNavbar, manageDropDownMenu, manageButtonDisplay } from 'new_components/navbar/navbar'
import { manageFooterPhoneNumber, manageSocialNetworksGtag } from 'new_components/footer/footer'
import { managePagePhoneNumber, manageDemoRequest } from 'new_components/page/page'
import { manageAlert } from 'new_components/alert/alert'
import { manageHomePagePopup } from 'new_components/home_page_popup/home_page_popup'
import { manageInfobanner } from 'new_components/infobanner/infobanner'
import { manageZendeskLink } from 'shared/helpers/zendesk_link'

// MEDIA
import 'shared/static/logo_eut.svg'
import 'shared/static/logo_prado.svg'
import 'shared/static/logo_rektangle.svg'
import 'shared/static/logo_artistic.jpg'
import 'shared/static/logo_akeha.svg'
import 'shared/static/witness.png'
import 'shared/static/logo_navbar.svg'
import 'shared/static/dropdown-arrow.png'
import 'shared/static/screen_dashboard.png'
import 'shared/static/screen_contract.png'
import 'shared/static/screen_employers.png'
import 'shared/static/screen_signature.png'

// NEW COMPONENTS
import 'new_components/form/form'
import 'new_components/container/container'
import 'new_components/section/section'
import 'new_components/picto_container/picto_container'
import 'new_components/button/button'
import 'new_components/double_button_box/double_button_box'
import 'new_components/slider/slider'
import 'new_components/slide_home/slide_home'
import 'new_components/slide_discover/slide_discover'
import 'new_components/slide_quote/slide_quote'
import 'new_components/small_square_box/small_square_box'
import 'new_components/grid/grid'
import 'new_components/newsletter_box/newsletter_box'
import 'new_components/box/box'
import 'new_components/staff_member_card/staff_member_card'
import 'new_components/sign_in_layout/sign_in_layout'

Rails.start()
manageNavbar()
manageFooterPhoneNumber()
manageAlert()
manageHomePagePopup()
manageCookieConsent()
manageDemoRequest()
managePagePhoneNumber()
manageSocialNetworksGtag()
manageInfobanner()
manageDropDownMenu()
manageButtonDisplay()
manageInfobanner()
manageZendeskLink()
